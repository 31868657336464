import { Injectable, Signal, inject, signal } from "@angular/core";
import { BehaviorSubject, Observable, Subject, of } from "rxjs";
import { catchError, switchMap, shareReplay, map, tap } from "rxjs/operators";
import { HttpHelperService } from "@helpers/http";
import { IHttpResponse } from "@helpers/http/interfaces";
import { environment } from "src/environments/environment";
import { ShopApiService } from "../shop";
import { menuCategoryListAddItem, menuCategoryFindById } from "./functions";
import { menuCategorySetAddProduct } from "./functions/menu.category.set.fn";
import {
  IMenuCategory,
  IMenuCategoryTree,
  IMenuCategoryTreeCriteria,
  TMenuCategoryContentDTO,
  TMenuCategoryRelatedProductDTO,
  TMenuCategoryRelatedProductPositionDTO,
} from "./interfaces";
import { IMerchant } from "@api/merchant/interfaces";
import { AlertController, ToastController } from "@ionic/angular";
import {
  injectQuery,
  injectInfiniteQuery,
  injectMutation,
  injectQueryClient,
  QueryObserverResult,
} from "@ngneat/query";
import * as qs from "qs";

import { toSignal } from "@angular/core/rxjs-interop";

@Injectable({
  providedIn: "root",
})
export class MenuCategoryApiService {
  #query = injectQuery();
  #queryInfinite = injectInfiniteQuery();

  #mutation = injectMutation();
  #client = injectQueryClient();
  #http = inject(HttpHelperService);
  #coreEndPoint = environment.coreEndPoint;

  newTree: Signal<QueryObserverResult<IMenuCategoryTree, Error>>;
  searchQuery = new Subject<{
    parent?: number;
    deep?: number;
    keywords?: string;
  }>();
  #mch = signal<IMerchant>(null);
  private alertCtrl = inject(AlertController);
  private toastCtrl = inject(ToastController);

  #tree$ = new BehaviorSubject<IMenuCategoryTree>(null);
  tree$ = this.#tree$.asObservable();

  #treeLoading$ = new BehaviorSubject<boolean>(false);
  treeLoading$ = this.#treeLoading$.asObservable();

  #list$ = new BehaviorSubject<IMenuCategory[]>([]);
  list$ = this.#list$.asObservable();

  #listSet$ = new BehaviorSubject<Set<number>>(new Set());
  listSet$ = this.#listSet$.asObservable();

  #listCount$ = new BehaviorSubject<number>(0);
  listCount$ = this.#listCount$.asObservable();

  // #category$ = new BehaviorSubject<IMenuCategory | null>(null);
  // category$ = this.#category$.asObservable();

  category$ = signal<IMenuCategory | null>(null);

  #loading$ = new BehaviorSubject<boolean>(false);
  loading$ = this.#loading$.asObservable();

  private shop$ = this.shopService.shop$;

  constructor(
    private http: HttpHelperService,
    private shopService: ShopApiService
  ) {
    this.newTree = toSignal(
      this.searchQuery.pipe(
        switchMap((queryParams) => {
          return this.getNewTree(1, queryParams).result$;
        })
      )
    );
  }

  getNewTree(mid: number, searchQuery = {}) {
    const queries = qs.stringify({
      ...searchQuery,
    });
    return this.#query({
      queryKey: ["menuCategoryTree"] as const,
      queryFn: () => {
        const path = `admin/menu/${mid}/categories/tree?${queries}`;
        return this.#http.get(path).pipe(map((res) => res.data));
      },
    });
  }

  updateContent<T>() {
    return this.#mutation({
      onSuccess: () => {
        this.#client.invalidateQueries({});
        this.presentToast("Content Updated !");
      },
      onError: this.errorHandler,
      mutationFn: ({ mid, cid, body }) => {
        const path = `admin/menu/${mid}/category/${cid}`;
        return this.#http.put(path, body).pipe(map((res) => res.data));
      },
    });
  }

  private errorHandler = async (error: any, variables, context) => {
    if (typeof error === "string") {
      const alert = await this.alertCtrl.create({
        header: `Error`,
        // subHeader: "Error",
        message: error,
        buttons: ["OK"],
      });

      alert.present();
    } else {
      const alert = await this.alertCtrl.create({
        header: error.name,
        subHeader: error.message,
        message: error.problems ? error.problems.join(", ") : "",
        buttons: ["OK"],
      });

      alert.present();
    }

    if (error.error instanceof ProgressEvent) {
      const alert = await this.alertCtrl.create({
        header: `ProgressEvent Error`,
        // subHeader: "Error",
        message: "Please try later",
        buttons: ["OK"],
      });

      alert.present();
    } else if (error.error) {
      const alert = await this.alertCtrl.create({
        header: error.error.name,
        subHeader: error.error.message,
        message: error.error.problems ? error.error.problems.join(", ") : "",
        buttons: ["OK"],
      });

      alert.present();
    }
  };

  private async presentToast(
    message: string,
    color: "danger" | "success" | "warning" = "success"
  ) {
    const toast = await this.toastCtrl.create({
      message,
      duration: 3000,
      buttons: [
        {
          text: "Dismiss",
          role: "cancel",
          handler: () => {
            console.log("Dismiss clicked");
          },
        },
      ],
      position: "top",
      positionAnchor: "header",
      color,
      swipeGesture: "vertical",
    });

    await toast.present();
  }

  set tree(value: IMenuCategoryTree) {
    this.#tree$.next(value);
  }

  set treeLoading(value: boolean) {
    this.#treeLoading$.next(value);
  }

  set list(value: IMenuCategory[]) {
    this.#list$.next(value);
  }

  set listSet(value: Set<number>) {
    this.#listSet$.next(value);
    this.listCount = value.size;
  }

  set listCount(value: number) {
    this.#listCount$.next(value);
  }

  // set category(value: IMenuCategory | null) {
  //   this.#category$.next(value);
  // }

  set loading(value: boolean) {
    this.#loading$.next(value);
  }

  initAll(): void {
    // this.tree = null;
    // this.list = [];
    // this.listSet = new Set();
    // this.category$.set(null);
  }

  getTree(
    menuId: number,
    criteria?: IMenuCategoryTreeCriteria
  ): Observable<IMenuCategoryTree> {
    return this.tree$.pipe(
      switchMap((tree) => {
        if (tree) {
          return of(tree);
        }

        const queryParams = "";
        return this.getTreeFromServer<IMenuCategoryTree>(
          menuId,
          queryParams
        ).pipe(map((res) => res.data));
      })
    );
  }

  getTreeFromServer<T>(
    mid: number,
    criteria = ""
  ): Observable<IHttpResponse<T>> {
    this.treeLoading = true;
    let path = `admin/menu/${mid}/categories/tree`;
    if (criteria) {
      path += `?${criteria}`;
    }
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap(() => (this.treeLoading = false)),
      tap((res: IHttpResponse<T>) => {
        if (res.success) {
          this.tree = res.data;
        }
      })
    );
  }

  getById(mid: number, cid: number) {
    return this.#query({
      queryKey: ["menuCategory"] as const,

      queryFn: () => {
        const path = `admin/menu/${mid}/category/${cid}`;
        return this.#http.get(path).pipe(map((res) => res.data));
      },
    });
  }

  getCategory(
    menuId: number,
    cid: number,
    token?: string
  ): Observable<IMenuCategory | null> {
    return this.list$.pipe(
      switchMap((list) => {
        const category = menuCategoryFindById(list, cid, token);
        if (category) {
          if (!token) {
            this.category$.set(category);
            return of(category);
          }

          if (token && category.token === token) {
            this.category$.set(category);
            return of(category);
          }
        }

        return this.getCategoryFromServer<IMenuCategory>(menuId, cid).pipe(
          map((res) => res?.data)
        );
      })
    );
  }

  getCategoryFromServer<T>(
    menuId: number,
    cid: number
  ): Observable<IHttpResponse<T>> {
    this.loading = true;
    const path = `admin/menu/${menuId}/category/${cid}`;
    return this.http.get(path).pipe(
      catchError(this.http.catch()),
      tap(() => (this.loading = false)),
      tap((res: IHttpResponse<T>) => {
        if (res.success && res.data) {
          this.category$.set(res.data);
          const el = menuCategorySetAddProduct(this.#listSet$.value, [
            res.data,
          ]);
          this.listSet = el;
          this.list = menuCategoryListAddItem(this.#list$.value, [res.data]);
        }
      })
    );
  }

  getCategoryList<T>(
    menuId: number,
    criteria = ""
  ): Observable<IHttpResponse<T>> {
    let path = `admin/menu/${menuId}/categories`;
    if (criteria) {
      path += `?${criteria}`;
    }
    return this.http.get(path).pipe();
  }

  getListFromCacheByLevel<T>(
    id: number = 1,
    body = {}
  ): Observable<IHttpResponse<T>> {
    return this.getListFromServerByLevel<T>(id).pipe(shareReplay());
  }

  getListFromServerByLevel<T>(
    id: number = 1,
    body = {}
  ): Observable<IHttpResponse<T>> {
    const path = `api/shop/${this.shop$()?.id}/menu/categories/${id}`;
    return this.http.get(path).pipe(catchError(this.http.catch()));
  }

  getFromCacheByCatId<T>(id: number): Observable<IHttpResponse<T>> {
    return this.getFromServerByCatId<T>(id).pipe(shareReplay());
  }

  getFromServerByCatId<T>(id: number): Observable<IHttpResponse<T>> {
    const path = `api/shop/${this.shop$()?.id}/menu/category/${id}`;
    return this.http.get(path).pipe(catchError(this.http.catch()));
  }

  addCategory() {
    return this.#mutation({
      onSuccess: () => {
        this.#client.invalidateQueries({});
        this.presentToast("Dish created !");
      },
      onError: this.errorHandler,
      mutationFn: ({
        mid,
        body,
      }: {
        mid: number;
        body: { parentId?: number; data: TMenuCategoryContentDTO };
      }) => {
        const path = `admin/menu/${mid}/category/add`;
        return this.#http.post(path, body).pipe(map((res) => res.data));
      },
    });
  }

  updateCategoryContent<T>(
    mid: number,
    cid: number,
    body: { data: TMenuCategoryContentDTO }
  ): Observable<IHttpResponse<T>> {
    this.loading = true;
    const path = `admin/menu/${mid}/category/${cid}`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => (this.loading = false))
    );
  }

  getRelatedProducts<T>(
    menuId: number,
    cid: number
  ): Observable<IHttpResponse<T>> {
    this.loading = true;
    const path = `${
      this.#coreEndPoint
    }/admin/menu/${menuId}/category/${cid}/related/products`;
    return this.http.get(path).pipe(tap(() => (this.loading = false)));
  }

  updateDish<T>() {
    return this.#mutation({
      onSuccess: () => {
        this.#client.invalidateQueries({
          queryKey: ["menuOption"],
        });

        this.#client.invalidateQueries({
          queryKey: ["menuDishes"],
        });

        this.#client.invalidateQueries({
          queryKey: ["menuDish"],
        });

        this.presentToast("Dishes Updated !");
      },
      onError: this.errorHandler,
      mutationFn: ({
        mid,
        cid,
        body,
      }: {
        mid: number;
        cid: number;
        body: TMenuCategoryRelatedProductDTO;
      }) => {
        const path = `admin/menu/${mid}/category/${cid}/related/products`;

        return this.#http.put(path, body).pipe(map((res) => res.data));
      },
    });
  }

  updateRelatedProducts<T>(
    mid: number,
    cid: number,
    body: TMenuCategoryRelatedProductDTO
  ): Observable<IHttpResponse<T>> {
    this.loading = true;
    const path = `${
      this.#coreEndPoint
    }/admin/menu/${mid}/category/${cid}/related/products`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => (this.loading = false))
    );
  }

  updateRelatedProductsPosition<T>(
    mid: number,
    cid: number,
    body: TMenuCategoryRelatedProductPositionDTO
  ): Observable<IHttpResponse<T>> {
    this.loading = true;
    const path = `${
      this.#coreEndPoint
    }/admin/menu/${mid}/category/${cid}/related/products/position`;
    return this.http.put(path, body).pipe(
      catchError(this.http.catch()),
      tap(() => (this.loading = false))
    );
  }
}
