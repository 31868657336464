import { EventEmitter, inject, Injectable, signal } from "@angular/core";
import { Observable, of } from "rxjs";
import {
  catchError,
  concatMap,
  map,
  retry,
  switchMap,
  take,
  tap,
} from "rxjs/operators";
import { StorageHelperService } from "@helpers/storage";
import { PRINTER_LIST } from "@helpers/storage/constants";
import { ToastService } from "../toast";
import {
  PaperWidthList,
  printerEvents,
  PrinterPortList,
  PrinterVendorList,
  PrinterVendorModelList,
  printerVendors,
  printModes,
} from "./constants";
import {
  IPrinterEvent,
  PrinterEvent,
  PrinterVendor,
  PrintMode,
} from "./interfaces";
import {
  IPrintBarCodeObj,
  IPrintCommand,
  IPrinterQRCode,
  IPrintImageObj,
  IPrintJob,
  IPrintObj,
  IPrintRasterObj,
} from "./interfaces/print";
import { IPrinter, PrinterStatus } from "./interfaces/printer";
import { PrintEventService } from "./print-event.service";
import { SunmiPrinterService } from "./sunmi";
import { StarService } from "./star";
import { checkUsbPermission } from "./printer.permission";
import { EscPosPrinterService } from "./escpos/escpos.service";
import { BrowserPrinterService } from "./browser";
import { AlertController } from "@ionic/angular";
import { Router } from "@angular/router";
import { ShopApiService } from "@api/shop";
import randombytes from "randombytes";
@Injectable({
  providedIn: "root",
})
export class PrinterService {
  public VendorList = PrinterVendorList;
  public PortList = PrinterPortList;
  public ModelList = PrinterVendorModelList;
  public PaperWidthList = PaperWidthList;
  loading$ = signal(false);
  printers = signal<Array<IPrinter>>([]);
  printer = signal<IPrinter | null>(null);
  private alertCtrl = inject(AlertController);
  private printEvent$: EventEmitter<{
    [key: string]: any;
    event: IPrinterEvent;
  }>;
  constructor(
    private storageService: StorageHelperService,
    private sunmiPrinterService: SunmiPrinterService,
    private browserPrinterService: BrowserPrinterService,
    private starPrintService: StarService,
    private escposPrintService: EscPosPrinterService,
    private toast: ToastService,
    private eventService: PrintEventService,
    private router: Router,
    private shopService: ShopApiService
  ) {
    this.printEvent$ = this.eventService.printEvent$;
    this.watchPrintEvent();
  }

  async noPrinterWarning() {
    let alert = await this.alertCtrl.create({
      header: "Warning",
      subHeader: `No Printer Found`,
      message: `Do you want to set up a printer ?`,
      mode: "ios",
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          handler: () => {},
        },
        {
          text: "Yes",
          role: "confirm",
          handler: () => {},
        },
      ],
    });

    await alert.present();

    const { role } = await alert.onDidDismiss();

    if (role === "confirm") {
      this.router.navigate([
        `/orgs/shops/${this.shopService.shop$().id}/printing/printers`,
      ]);
      return true;
    } else {
      return false;
    }
  }

  setDefaultPinterById(id?: string) {
    let printer: IPrinter | null = null;
    printer = this.printers().find((p) => p.id === id);
    if (!id) {
      printer = this.printers()[0];
    }

    if (!printer) {
      return this.defaultResponse(printerEvents.PRINTER_UPDATE_FAILED);
    }

    const newList = this.printers().map((target) => {
      if (target.id === printer.id) target.isDefault = true;
      if (target.id !== printer.id) target.isDefault = false;
      return target;
    });

    return this.storageService.set(PRINTER_LIST, newList).pipe(
      take(1),
      catchError((error) =>
        this.defaultResponse(printerEvents.PRINTER_UPDATE_FAILED)
      ),
      switchMap(() => {
        this.printers.set(newList);
        this.printer.set(printer);
        return this.defaultResponse(printerEvents.PRINTER_UPDATED);
      })
    );
  }

  setPrinterById(id: string): void {
    const printer = this.printers().find((p) => p.id === id);

    if (printer) this.printer.set(printer);
  }

  getPrinterById(id: string) {
    const printer = this.printers().find((p) => p.id === id);
    return printer;
  }

  init() {
    return this.storageService.get(PRINTER_LIST).pipe(
      catchError((error) =>
        this.defaultResponse(printerEvents.PRINTER_LIST_INITIALIZE_FAILED)
      ),
      switchMap((res) => {
        const event: IPrinterEvent = {
          event: printerEvents.PRINTER_LIST_INITIALIZED,
        };

        if (!res) {
          return this.initPrinterList().pipe(
            tap((list) => {
              this.printers.set(list);
              this.eventNotification(event);
            })
          );
        }

        this.printers.set(res);
        let printer = this.printers().find((p) => p.isDefault);

        if (!printer && this.printers().length) {
          printer = this.printers()[0];
          this.setDefaultPinterById(printer.id).pipe(take(1)).subscribe();
        }

        if (printer) {
          this.printer.set(printer);

          if (printer.port === "USB") {
            this.portNameControl(printer);
          }
        }

        this.eventNotification(event);
        return of<IPrinterEvent>(event);
      })
    );
  }

  private portNameControl(printer: IPrinter) {
    const printObj: IPrintObj = {
      paperWidth: 365,
      text: `${randombytes(8).toString("hex")}\n\n\n\n`,
    };

    this.print(1, this.printer(), printModes.text, printObj)
      .pipe(
        switchMap((res) => {
          const { event } = res;

          if (event.code === printerEvents.PRINT_JOB_FAILED.code) {
            return this.portNameUpdate(printer);
          }

          return of();
        })
      )
      .subscribe();
  }

  portNameUpdate(printer: IPrinter) {
    return this.searchPort(printer).pipe(
      switchMap((res) => {
        const { event, data } = res;

        if (event.code === printerEvents.PORT_SEARCH_STOP.code) {
          const devices = data.printers ?? [];

          const target = devices.find(
            (device) => device.usbSerialNumber === printer.usbSerialNumber
          );
          if (target && target.portName !== printer.portName) {
            printer.portName = target.portName;
            return this.update(printer);
          }
        }

        return of();
      })
    );
  }

  searchPort(printer: IPrinter): Observable<IPrinterEvent> {
    switch (printer.vendorCode) {
      case printerVendors.browser.code:
        return this.browserPrinterService.search(true).pipe(
          tap((event) => this.eventNotification(event)),
          catchError((event) => {
            this.eventNotification(event, "danger");
            return of(event);
          })
        );

      case printerVendors.sunmi.code:
        return this.sunmiPrinterService.search(true).pipe(
          tap((event) => this.eventNotification(event)),
          catchError((event) => {
            this.eventNotification(event, "danger");
            return of(event);
          })
        );

      //? Star Printer
      case printerVendors.star.code:
        if (printer.port === "USB") {
          return checkUsbPermission().pipe(
            switchMap((res) => {
              if (!res) {
                return this.defaultResponse(
                  printerEvents.PRINT_JOB_FAILED,
                  "USB Permission Grant Failed"
                );
              }

              return this.starPrintService.search(printer, true).pipe(
                tap((event) => this.eventNotification(event)),
                catchError((event) => {
                  this.eventNotification(event, "danger");
                  return of(event);
                })
              );
            })
          );
        }

        return this.starPrintService.search(printer, true).pipe(
          tap((event) => this.eventNotification(event)),
          catchError((event) => {
            this.eventNotification(event, "danger");
            return of(event);
          })
        );

      //? ESC POS
      case printerVendors.escpos.code:
        return this.escposPrintService.search(printer, true).pipe(
          tap((event) => this.eventNotification(event)),
          catchError((event) => {
            this.eventNotification(event, "danger");
            return of(event);
          })
        );

      default:
        return this.defaultResponse(
          printerEvents.PORT_SEARCH_STOP,
          "Unsupported printer type"
        );
    }
  }

  getSerialNo(vendor: PrinterVendor): Observable<IPrinterEvent> {
    switch (vendor.code) {
      case printerVendors.sunmi.code:
        return this.sunmiPrinterService.getPrinterSerialNo().pipe(
          tap((event) => this.eventNotification(event)),
          catchError((event) => {
            this.eventNotification(event, "danger");
            return of(event);
          })
        );

      default:
        return this.defaultResponse(
          printerEvents.SERIAL_NUMBER_SEARCH_STOP,
          "Unsupported printer type"
        );
    }
  }

  getVersion(vendor: PrinterVendor): Observable<IPrinterEvent> {
    switch (vendor.code) {
      case printerVendors.sunmi.code:
        return this.sunmiPrinterService.getPrinterVersion().pipe(
          tap((event) => this.eventNotification(event)),
          catchError((event) => {
            this.eventNotification(event, "danger");
            return of(event);
          })
        );

      default:
        return this.defaultResponse(
          printerEvents.VERSION_SEARCH_STOP,
          "Unsupported printer type"
        );
    }
  }

  selfChecking(vendor: PrinterVendor): Observable<IPrinterEvent> {
    switch (vendor.code) {
      case printerVendors.sunmi.code:
        return this.sunmiPrinterService.selfChecking().pipe(
          tap((event) => this.eventNotification(event)),
          catchError((event) => {
            this.eventNotification(event, "danger");
            return of(event);
          })
        );

      default:
        return this.defaultResponse(
          printerEvents.SELF_CHECKING_STOP,
          "Unsupported printer type"
        );
    }
  }

  statusStartListener(printer: IPrinter): Observable<IPrinterEvent> {
    switch (printer.vendor.code) {
      case printerVendors.sunmi.code:
        return this.sunmiPrinterService.statusStartListener().pipe(
          tap((event) => this.eventNotification(event)),
          catchError((event) => {
            this.eventNotification(event, "danger");
            return of(event);
          })
        );

      // TODO add star service
      case printerVendors.star.code:
        return this.starPrintService.statusStartListener().pipe(
          tap((event) => this.eventNotification(event)),
          catchError((event) => {
            this.eventNotification(event, "danger");
            return of(event);
          })
        );

      default:
        return this.defaultResponse(
          printerEvents.STATUS_LISTENER_START,
          "Unsupported printer type"
        );
    }
  }

  statusStopListener(vendor: PrinterVendor): Observable<IPrinterEvent> {
    switch (vendor.code) {
      case printerVendors.sunmi.code:
        return this.sunmiPrinterService.statusStopListener().pipe(
          tap((event) => this.eventNotification(event)),
          catchError((event) => {
            this.eventNotification(event, "danger");
            return of(event);
          })
        );

      default:
        return this.defaultResponse(
          printerEvents.STATUS_LISTENER_STOP,
          "Unsupported printer type"
        );
    }
  }

  print(
    copy = 1,
    printer: IPrinter,
    printMode: PrintMode,
    data: IPrintObj | IPrintImageObj,
    job?: IPrintJob
  ) {
    if (printer.port === "USB") {
      return checkUsbPermission().pipe(
        switchMap((res) => {
          if (!res) {
            return this.defaultResponse(
              printerEvents.PRINT_JOB_FAILED,
              "USB Permission Grant Failed"
            );
          }

          switch (printMode.code) {
            case printModes.base64.code:
              const base64Obj = data as IPrintImageObj;
              return this.printerImage(copy, printer, base64Obj, job);
            case printModes.text.code:
              const textObj = data as IPrintObj;
              return this.printerString(copy, printer, textObj, job);
            case printModes.raster.code:
              const rasterObj = data as IPrintObj;
              return this.printerRasterString(copy, printer, rasterObj, job);
            // TODO
            // case PrintMode.COMMAND_LINE:
            //   return this.printCommandLine(printer, data, jobId);
            // case PrintMode.BARCODE:
            //   return this.printerBarCode(printer, data, jobId);
            // case PrintMode.QRCODE:
            //   return this.printerBarCode(printer, data, jobId);
            default:
              return this.defaultResponse(
                printerEvents.PRINT_JOB_FAILED,
                "Unsupported printer mode"
              );
          }
        })
      );
    }

    switch (printMode.code) {
      case printModes.base64.code:
        const base64Obj = data as IPrintImageObj;
        return this.printerImage(copy, printer, base64Obj, job);
      case printModes.text.code:
        const textObj = data as IPrintObj;
        return this.printerString(copy, printer, textObj, job);
      case printModes.raster.code:
        const rasterObj = data as IPrintObj;
        return this.printerRasterString(copy, printer, rasterObj, job);
      // TODO
      // case PrintMode.COMMAND_LINE:
      //   return this.printCommandLine(printer, data, jobId);
      // case PrintMode.BARCODE:
      //   return this.printerBarCode(printer, data, jobId);
      // case PrintMode.QRCODE:
      //   return this.printerBarCode(printer, data, jobId);
      default:
        return this.defaultResponse(
          printerEvents.PRINT_JOB_FAILED,
          "Unsupported printer mode"
        );
    }
  }

  add(printer: IPrinter) {
    const newList = [...this.printers(), ...[printer]];

    return this.storageService.set(PRINTER_LIST, newList).pipe(
      take(1),
      catchError((error) =>
        this.defaultResponse(printerEvents.PRINTER_SAVE_FAILED)
      ),
      switchMap(() => {
        this.printers.set(newList);
        return this.defaultResponse(printerEvents.PRINTER_SAVED);
      })
    );
  }

  update(printer: IPrinter) {
    this.printers.update((list) => {
      list = list.map((target) => {
        if (target.id === printer.id) {
          if (printer.isDefault) this.setPrinterById(printer.id);
          return printer;
        } else {
          return target;
        }
      });

      return list;
    });

    return this.storageService.set(PRINTER_LIST, this.printers()).pipe(
      take(1),
      catchError((error) =>
        this.defaultResponse(printerEvents.PRINTER_UPDATE_FAILED)
      ),
      switchMap(() => {
        return this.defaultResponse(printerEvents.PRINTER_UPDATED);
      })
    );
  }

  delete(printer: IPrinter) {
    const newList = this.printers().filter(
      (target) => target.id !== printer.id
    );

    return this.storageService.set(PRINTER_LIST, newList).pipe(
      take(2),
      catchError((error) =>
        this.defaultResponse(printerEvents.PRINTER_DELETED_FAILED)
      ),
      switchMap(() => {
        this.printers.set(newList);
        if (printer.isDefault) {
          return this.setDefaultPinterById();
        } else {
          return of(true);
        }
      }),
      switchMap(() => this.defaultResponse(printerEvents.PRINTER_DELETED))
    );
  }

  private initPrinterList() {
    return this.storageService.set(PRINTER_LIST, []).pipe(map(() => []));
  }

  private printerString(
    copy = 1,
    printer: IPrinter,
    data: IPrintObj,
    job?: IPrintJob
  ): Observable<IPrinterEvent> {
    switch (printer.vendor.code) {
      case printerVendors.sunmi.code:
        return this.sunmiPrinterService.printString(copy, data, job);

      case printerVendors.star.code:
        return this.starPrintService.printString(copy, printer, data, job);

      case printerVendors.escpos.code:
        return this.escposPrintService.printString(copy, printer, data, job);

      default:
        const event: IPrinterEvent = {
          event: printerEvents.PRINT_JOB_FAILED,
          message: "Unsupported printer type",
          data: {
            job,
          },
        };

        this.printEvent$.emit({ event, toast: { color: "danger" } });
        return of<IPrinterEvent>(event);
    }
  }

  private printerRasterString(
    copy = 1,
    printer: IPrinter,
    data: IPrintRasterObj,
    job?: IPrintJob
  ): Observable<IPrinterEvent> {
    switch (printer.vendor.code) {
      case printerVendors.star.code:
        return this.starPrintService.printRasterString(
          copy,
          printer,
          data,
          job
        );

      default:
        const event: IPrinterEvent = {
          event: printerEvents.PRINT_JOB_FAILED,
          message: "Unsupported printer type",
          data: {
            job,
          },
        };

        this.printEvent$.emit({ event, toast: { color: "danger" } });
        return of<IPrinterEvent>(event);
    }
  }

  private printerImage(
    copy = 1,
    printer: IPrinter,
    data: IPrintImageObj,
    job?: IPrintJob
  ): Observable<IPrinterEvent> {
    switch (printer.vendor.code) {
      case printerVendors.sunmi.code:
        return this.sunmiPrinterService.printImage(data, job);

      case printerVendors.star.code:
        return this.starPrintService.printImage(copy, printer, data, job);

      case printerVendors.escpos.code:
        return this.escposPrintService.printImage(printer, data, job);

      default:
        const event: IPrinterEvent = {
          event: printerEvents.PRINT_JOB_FAILED,
          message: "Unsupported printer type",
          data: {
            job,
          },
        };

        this.printEvent$.emit({ event, toast: { color: "danger" } });
        return of<IPrinterEvent>(event);
    }
  }

  private printCommandLine(
    copy = 1,
    printer: IPrinter,
    data: IPrintCommand[],
    job?: IPrintJob
  ): Observable<IPrinterEvent> {
    switch (printer.vendor.code) {
      // case printerVendors.star.code:
      //   return this.starService.printCommandLine(copy, printer, data, job);

      default:
        const event: IPrinterEvent = {
          event: printerEvents.PRINT_JOB_FAILED,
          message: "Unsupported printer type",
          data: {
            job,
          },
        };

        this.printEvent$.emit({ event, toast: { color: "danger" } });
        return of<IPrinterEvent>(event);
    }
  }

  private printerBarCode(
    printer: IPrinter,
    data: IPrintBarCodeObj,
    job?: IPrintJob
  ): Observable<IPrinterEvent> {
    switch (printer.vendor.code) {
      case printerVendors.sunmi.code:
        return this.sunmiPrinterService.printBarCode(data, job);

      default:
        const event: IPrinterEvent = {
          event: printerEvents.PRINT_JOB_FAILED,
          message: "Unsupported printer type",
          data: {
            job,
          },
        };

        this.printEvent$.emit({ event, toast: { color: "danger" } });
        return of<IPrinterEvent>(event);
    }
  }

  private printerQrCode(
    vendor: PrinterVendor,
    data: IPrinterQRCode,
    jobId?: string
  ): Observable<IPrinterEvent> {
    switch (vendor.code) {
      case printerVendors.sunmi.code:
        return this.sunmiPrinterService.printQrCode(data);
      default:
        const event: IPrinterEvent = {
          event: printerEvents.PRINT_JOB_FAILED,
          message: "Unsupported printer type",
          data: {
            jobId,
          },
        };

        this.printEvent$.emit({ event, toast: { color: "danger" } });
        return of<IPrinterEvent>(event);
    }
  }

  private watchPrintEvent() {
    this.printEvent$
      .subscribe
      //   (next: ISharedEventEntity) => {
      //   // TODO
      // }
      ();
  }

  private defaultResponse(
    event: PrinterEvent,
    message: string = null
  ): Observable<IPrinterEvent> {
    return of<IPrinterEvent>({ event, message, data: {} }).pipe(
      tap((data) => this.eventNotification(data))
    );
  }

  private eventNotification(data: IPrinterEvent, color: string = null) {
    const { event, message } = data;
    if (event?.name || message) {
      this.toast.listener$.next({
        header: event?.name ?? "",
        message,
        color,
      });
    }
  }
}
