import { EventEmitter, Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Observable, of, Subscriber } from "rxjs";

import { v1 as uuidv1 } from "uuid";
import { catchError, map, switchMap } from "rxjs/operators";

import { PrintEventService } from "../print-event.service";
import { IPrinterEvent, IPrintJob, PrinterEvent } from "../interfaces";
import { IPrinter, PrinterStatus } from "../interfaces/printer";
import {
  PrinterVendorModelList,
  printerEvents,
  printerVendors,
} from "../constants";
import {
  IPrintCommand,
  IPrintImageObj,
  IPrintObj,
  IPrintRasterObj,
} from "../interfaces/print";
// declare var cordova;
declare var starprnt;
@Injectable({
  providedIn: "root",
})
export class StarService {
  private modelList = PrinterVendorModelList.STAR;
  private printEvent$: EventEmitter<{
    [key: string]: any;
    event: IPrinterEvent;
  }>;
  constructor(
    private platform: Platform,

    private eventService: PrintEventService
  ) {
    this.printEvent$ = this.eventService.printEvent$;
  }

  private cordovaPlatformRequired(
    eventType: PrinterEvent = null,
    observer: Subscriber<IPrinterEvent>,
    data?: any
  ) {
    observer.error({ name: eventType, message: "cordova_platform_required" });
    observer.complete();
    return observer;
  }

  search(printer: IPrinter, mokeup = false): Observable<IPrinterEvent> {
    return new Observable<IPrinterEvent>((observer) => {
      if (!this.platform.is("cordova")) {
        if (mokeup) {
          return observer.next({
            event: printerEvents.PORT_SEARCH_STOP,
            data: {
              printers: [
                {
                  vendor: printerVendors.star,
                  // modelName: PrinterVendor.STAR,
                  portName: "1",
                  id: uuidv1(),
                },
              ],
            },
          });
        } else {
          return this.cordovaPlatformRequired(
            printerEvents.PORT_SEARCH_STOP,
            observer
          );
        }
      }

      const port = printer.port === "TCP" ? "LAN" : printer.port;

      starprnt.portDiscovery(
        port,
        (res) =>
          this.onFnSuccess(printerEvents.PORT_SEARCH_STOP, observer, res),
        (res) => this.onFnFailed(printerEvents.PORT_SEARCH_STOP, observer, res)
      );

      return observer.next({ event: printerEvents.PORT_SEARCH_START });
    });
  }

  statusStartListener(): Observable<IPrinterEvent> {
    return starprnt.getStatus().pipe(
      map((res) => {
        console.log(res);

        const event: IPrinterEvent = {
          event: printerEvents.STATUS_LISTENER_START,
          data: {
            printerStatus: res,
          },
        };
        return event;
      })
    );
  }

  statusCheck<T>(printer: IPrinter): Observable<T | PrinterStatus> {
    return new Observable<any>((o) => {
      let event = null;
      const model = this.modelList.find(
        (item) => item.name === printer.modelName
      );
      const emulation = model ? model.emulation : null;
      event = {
        event: printerEvents.PRINTER_STATUS_CHECKING,
        message: "Printer connecting....",
      };
      this.printEvent$.emit({ event, toast: {} });
      starprnt.checkStatus(
        printer.portName,
        emulation,
        (res) => {
          o.next(res);
        },
        (e) => {
          event = {
            name: printerEvents.PRINTER_STATUS_CHECK_FAILED,
            message: e,
          };

          this.printEvent$.emit({ event, toast: { color: "danger" } });
          o.error(event);
        }
      );
    });
  }

  connect(printer: IPrinter, job?: IPrintJob): Observable<IPrinterEvent> {
    if (!this.platform.is("cordova")) {
      const event = {
        event: printerEvents.PRINTER_CONNECT_FAILED,
        message: "cordova_platform_required",
        data: {
          job,
        },
      };

      this.printEvent$.emit({ event, toast: { color: "danger" } });
      return new Observable<IPrinterEvent>((o) => {
        o.error(event);
      });
    }

    const model = this.modelList.find(
      (item) => item.name === printer.modelName
    );
    const emulation = model ? model.emulation : null;
    return starprnt.connect(printer.portName, emulation.code, false).pipe(
      switchMap((next) => {
        const event = {
          event: printerEvents.PRINTER_CONNECTED,
        };
        this.printEvent$.emit({ event, toast: {} });
        return of<IPrinterEvent>(event);
      }),
      catchError((error) => {
        const event = {
          event: printerEvents.PRINTER_CONNECT_FAILED,
          message: JSON.stringify(error),
          data: {
            job,
          },
        };
        this.printEvent$.emit({ event, toast: { color: "danger" } });
        return new Observable<IPrinterEvent>((o) => {
          o.error(event);
        });
      })
    );
  }

  disconnect(): Observable<IPrinterEvent> {
    if (!this.platform.is("cordova")) {
      const event = {
        event: printerEvents.PRINTER_DELETED_FAILED,
        message: "cordova_platform_required",
      };

      this.printEvent$.emit({ event, toast: { color: "danger" } });
      return of<IPrinterEvent>(event);
    }

    return new Observable<IPrinterEvent>((observer) => {
      starprnt.disconnect(
        () => {
          const event = {
            event: printerEvents.PRINTER_DISCONNECTED,
          };
          this.printEvent$.emit({ event, toast: {} });
          observer.next(event);
        },
        () => {
          const event = {
            event: printerEvents.PRINTER_DISCONNECT_FAILED,
          };
          this.printEvent$.emit({ event, toast: { color: "danger" } });
          observer.error(event);
        }
      );

      return observer;
    });
  }

  printString(
    copy = 1,
    printer: IPrinter,
    data: IPrintObj,
    job?: IPrintJob
  ): Observable<IPrinterEvent> {
    return new Observable<IPrinterEvent>((observer) => {
      if (!this.platform.is("cordova")) {
        const event = {
          event: printerEvents.PRINT_JOB_FAILED,
          message: "cordova_platform_required",
          data: {
            job,
          },
        };

        this.printEvent$.emit({ event, toast: { color: "danger" } });

        return this.cordovaPlatformRequired(
          printerEvents.PRINT_JOB_FAILED,
          observer
        );
      }

      const model = this.modelList.find(
        (item) => item.name === printer.modelName
      );
      const emulation = model ? model.emulation : null;
      for (let i = 0; i < copy; i++) {
        starprnt.printRawText(
          printer.portName,
          emulation.code,
          data,
          (res) =>
            this.onFnSuccess(printerEvents.PRINT_JOB_DONE, observer, res, {
              job,
              index: i,
            }),
          (res) =>
            this.onFnFailed(printerEvents.PRINT_JOB_FAILED, observer, res, {
              job,
              index: i,
            })
        );
      }

      observer.next({
        event: printerEvents.PRINT_JOB_SENT,
        message: "Print job sent",
      });
      return observer;
    });
  }

  printImage(
    copy = 1,
    printer: IPrinter,
    data: IPrintImageObj,
    job?: IPrintJob
  ): Observable<IPrinterEvent> {
    return new Observable<IPrinterEvent>((observer) => {
      if (!this.platform.is("cordova")) {
        if (!this.platform.is("cordova")) {
          const event = {
            event: printerEvents.PRINT_JOB_FAILED,
            message: "cordova_platform_required",
            data: {
              job,
            },
          };

          this.printEvent$.emit({ event, toast: { color: "danger" } });

          return this.cordovaPlatformRequired(
            printerEvents.PRINT_JOB_FAILED,
            observer
          );
        }
      }

      const model = this.modelList.find(
        (item) => item.name === printer.modelName
      );
      const emulation = model ? model.emulation : null;

      for (let i = 0; i < copy; i++) {
        starprnt.printBase64Image(
          printer.portName,
          emulation.code,
          data,
          (res) =>
            this.onFnSuccess(printerEvents.PRINT_JOB_DONE, observer, res, {
              file: data.file,
              job,
              index: i,
            }),
          (res) =>
            this.onFnFailed(printerEvents.PRINT_JOB_FAILED, observer, res, {
              file: data.file,
              job,
              index: i,
            })
        );
      }

      observer.next({
        event: printerEvents.PRINT_JOB_SENT,
        message: "Print job sent",
      });
      return observer;
    });
  }

  printRasterString(
    copy = 1,
    printer: IPrinter,
    data: IPrintRasterObj,
    job?: IPrintJob
  ): Observable<IPrinterEvent> {
    return new Observable<IPrinterEvent>((observer) => {
      if (!this.platform.is("cordova")) {
        const event = {
          event: printerEvents.PRINT_JOB_FAILED,
          message: "cordova_platform_required",
          data: {
            job,
          },
        };

        this.printEvent$.emit({ event, toast: { color: "danger" } });

        return this.cordovaPlatformRequired(
          printerEvents.PRINT_JOB_FAILED,
          observer
        );
      }

      const model = this.modelList.find(
        (item) => item.name === printer.modelName
      );
      const emulation = model ? model.emulation : null;

      for (let i = 0; i < copy; i++) {
        const counter = i * 1000;
        setTimeout(() => {
          console.log("native called", i, counter);

          starprnt.printRasterReceipt(
            printer.portName,
            emulation.code,
            data,
            (res) =>
              this.onFnSuccess(printerEvents.PRINT_JOB_DONE, observer, res, {
                job,
                index: i,
              }),
            (res) =>
              this.onFnFailed(printerEvents.PRINT_JOB_FAILED, observer, res, {
                job,
                index: i,
              })
          );
        }, counter);
      }

      observer.next({
        event: printerEvents.PRINT_JOB_SENT,
        message: "Print job sent",
      });
      return observer;
    });
  }

  printCommandLine(
    copy = 1,
    printer: IPrinter,
    data: IPrintCommand[],
    job?: IPrintJob
  ): Observable<IPrinterEvent> {
    return new Observable<IPrinterEvent>((observer) => {
      if (!this.platform.is("cordova")) {
        if (!this.platform.is("cordova")) {
          const event = {
            event: printerEvents.PRINT_JOB_FAILED,
            message: "cordova_platform_required",
            data: {
              job,
            },
          };

          this.printEvent$.emit({ event, toast: { color: "danger" } });

          return this.cordovaPlatformRequired(
            printerEvents.PRINT_JOB_FAILED,
            observer
          );
        }
      }

      const model = this.modelList.find(
        (item) => item.name === printer.modelName
      );
      const emulation = model ? model.emulation : null;

      for (let i = 0; i < copy; i++) {
        starprnt.print(
          printer.portName,
          emulation.code,
          data,
          (res) =>
            this.onFnSuccess(printerEvents.PRINT_JOB_DONE, observer, res, {
              job,
              index: i,
            }),
          (res) =>
            this.onFnFailed(printerEvents.PRINT_JOB_FAILED, observer, res, {
              job,
              index: i,
            })
        );
      }

      observer.next({
        event: printerEvents.PRINT_JOB_SENT,
        message: "Print job sent",
      });
      return observer;
    });
  }

  private onPrintFinished(printResult: any, params?: any): IPrinterEvent {
    console.log("print result", printResult);
    if (params && params.file) {
      params.file.remove(
        () => {},
        () => {}
      );
    }
    const event = {
      event: printerEvents.PRINT_JOB_DONE,
      message: "print_job done",
      data: { ...printResult, ...params },
    };

    this.disconnect();
    return event;
  }

  private onPortSearchFinished(searchResult: Array<IPrinter>): IPrinterEvent {
    console.log("searchResult", searchResult);
    let event: IPrinterEvent = {};
    if (searchResult.length > 0) {
      event = {
        event: printerEvents.PORT_SEARCH_STOP,
        message: "print_found",
        data: {
          printers: searchResult.map((item) => {
            return {
              id: uuidv1(),
              vendor: printerVendors.star,
              modelName: item.modelName,
              portName: item.portName,
              macAddress: item.macAddress,
              usbSerialNumber: item.USBSerialNumber,
              selfChecking: false,
            };
          }),
        },
      };
    } else {
      event = {
        event: printerEvents.PORT_SEARCH_STOP,
        message: "print_not_found",
        data: {
          printers: [],
        },
      };
    }

    return event;
  }

  private onFnSuccess(
    eventType: PrinterEvent = null,
    observer: Subscriber<IPrinterEvent> = null,
    data?: any,
    params?: any
  ) {
    let event: IPrinterEvent = {};
    switch (eventType) {
      case printerEvents.PORT_SEARCH_STOP:
        event = this.onPortSearchFinished(data);
        break;
      case printerEvents.SERIAL_NUMBER_SEARCH_STOP:
        event = {
          data: {
            printer: {
              serialNumber: data,
            },
          },
        };
        break;

      case printerEvents.VERSION_SEARCH_STOP:
        event = {
          data: {
            printer: {
              version: data,
            },
          },
        };
        break;

      case printerEvents.PRINT_JOB_DONE:
        event = this.onPrintFinished(data, params);
        break;

      case printerEvents.SELF_CHECKING_STOP:
        console.log("self checking", data);
        event = {
          event: eventType,
          message: "Self Checking Succeed",
        };
        break;

      default:
        event = { event: printerEvents.UNKNOWN_EVENT, message: "job_done" };
        break;
    }

    this.printEvent$.emit({ event, toast: {} });

    if (observer) {
      observer.next(event);
      return observer;
    }
  }

  private onFnFailed(
    eventType: PrinterEvent = null,
    observer: Subscriber<IPrinterEvent> = null,
    error?: any,
    params?: any
  ) {
    const event: IPrinterEvent = {
      event: eventType,
      message: JSON.stringify(error),
      data: params,
    };

    switch (eventType) {
      case printerEvents.PRINT_JOB_FAILED:
        if (params && params.file) {
          params.file.remove(() => {}, this.onFnFailed());
        }
        break;

      default:
        break;
    }

    // this.printEvent$.emit({ event, toast: {} });

    if (observer) {
      return observer.error(event);
    }
  }
}
